.module-display {
    .header {
        display: flex;
        flex-direction: column;
        
        .title {
            font-size: 2rem;
        }

        .name {
            font-size: 1.5rem;
            font-weight: lighter;
        }
    }

    .docs-sidebar {
        width: fit-content;
        padding-right: 20px;
        box-shadow: var(--box-shadow);
        background-color: var(--secondary-background);
        padding: 10px;
        position: fixed;
        overflow-y: scroll;
        height: 100vh;

        .styled-nav {
            list-style: none;
            margin: 20px 10px;
            padding: 0px;
            margin-bottom: 30px;

            .header {
                font-size: 1.2rem;
                margin-bottom: 7px;
            }

            .child {
                border-left: 2px solid black;
                padding: 10px;
                padding-left: 30px;
                transition: all 0.5s ease;

                &:hover {
                    padding-left: 40px;
                }
                
                * {
                    text-decoration: none;
                }
                
                .title {
                    font-size: 1rem;
                    display: block;
                }

                .name {
                    display: block;
                    font-size: 0.9rem;
                }
            }
        }
    }

    .module-content {
        padding-top: 20px;
        margin-left: 280px;

        .symbol-section {
            margin-bottom: 20px;
            
            .header {
                font-size: 1.5rem;
            }

            .symbol-definition {
                box-shadow: var(--box-shadow);
                width: 95%;
                margin-top: 20px;
                padding: 10px;

                .symbol-header {
                    margin-bottom: 10px;
                    .symbol {
                        display: block;
                        font-size: 1.3rem;
                    }
                    
                    .source {
                        margin-top: 10px;
                        display: block;
                        font-weight: lighter;
                        font-size: 1rem;
                    }
                }

                .example {                    
                    .example-header {
                    margin-top: 40px;
                    display: block;
                        text-align: center;
                    }
                    
                    pre {
                        border-top: 1px solid black;
                        border-bottom: 1px solid black;
                    }
                }

                .class-methods {
                    .methods-header {
                        font-size: 1.2rem;
                        display: block;
                        text-align: center;
                        margin-top: 40px;
                        margin-bottom: 20px;
                    }

                    .method {
                        border-left: 2px solid black;
                        border-right: 2px solid black;
                        padding: 10px;
                        padding-left: 30px;
                        margin-top: 10px;
                        margin-bottom: 10px;

                        span {
                            display: block;
                        }
                        
                        .method-header {
                            margin-bottom: 10px;

                            .method-name {
                                font-size: 1.1rem;
                            }
    
                            .method-source {
                                font-size: 1rem;
                                font-weight: lighter;
                            }
                        }
                    }
                }
            }
        }
    }
}