.reference-table {
    span,
    .arrow {
        display: inline-block;
    }

    .arrow {
        margin-left: 10px;
        margin-right: 3px;
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 5px solid var(--primary-font-color);
    }

    span:nth-of-type(2) {
        color: var(--secondary-font-color);
    }
}