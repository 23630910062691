.note {
    padding: 20px;
    background-color: var(--secondary-accent-background);
    
    display: flex;
    align-items: center;
    width: fit-content;
    margin: 30px auto;

    #light-bulb {
        font-size: 2rem;
        margin: 0px 20px;

        & > path {
            color: white;
        }
    }

    .note-message {
        color: white;
        font-size: 1.1rem;

        & > * {
            color: white;
            font-size: 1.1rem;
        }
    }
}