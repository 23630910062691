.docs-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;

    h1 {
        font-size: 3rem;
    }

    span {
        font-size: 1.5rem;
        text-align: center;
    }
}

.quick-refs {
    width: 100%;
    .refs-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .quick-ref {
            background-color: var(--secondary-background);
            box-shadow: var(--box-shadow);
            transition: background-color 0.5s ease;
            
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 100px;
            padding-top: 100px;
            width: calc(49% - 10px);
            margin: 10px;
            box-sizing: border-box;
            position: relative;

            text-decoration: none;

            &:hover {
                cursor: pointer;
                background-color: var(--primary-background);

                h1 {
                    transform: translate(-50%, -150%);
                    opacity: 0;
                }

                span {
                    opacity: 1;
                }
            }

            h1 {
                opacity: 1;
                position: absolute;
                left: 50%;
                transform: translate(-50%, 0%);
                
                transition: all 0.5s ease;
            }

            span {
                display: block;
                text-align: center;
                opacity: 0;
                font-size: 2rem;
                transition: all 0.5s ease;
            }
        }
    }
}