.sidebar-nav {
    position: fixed;
    display: flex;
    flex-direction: column;
    height: 100%;
    
    padding: 0;
    margin: 0;
    
    width: 250px;
    top: 0px;

    z-index: 1000;
    
    background-color: var(--primary-accent-background);
    transition: all 0.6s cubic-bezier(0.945, 0.020, 0.270, 0.665);

    * {
        color: var(--primary-accent-font-color);
    }

    &.active {
        margin-left: -210px;
        
        .page {
            opacity: 0%;
            
            * {
                cursor: default !important;
            }
        }

        .footer {
            cursor: default;
        }
    }

    .header {
        display: flex;
        width: 100%;        

        justify-content: space-between;

        .align {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .title {
                padding-left: 15px;
                width: 110%;
                text-align: center;
                font-size: 1.1rem;
                font-weight: bold;
            }

            .collapse-sidebar {
                width: 40px;
                height: 40px;
                cursor: pointer;
                background: none;
                border: none;
    
                div {
                    width: 80%;
                    height: 2px;
                    margin: 0 auto;
                    background: white;
                    transition: all 0.8s cubic-bezier(0.810, -0.330, 0.345, 1.375);
                    transition-delay: 0.2s;
    
                    &:first-of-type {
                        transform: rotate(45deg) translate(2px, 2px);
                    }
    
                    &:nth-of-type(2) {
                        opacity: 0;
                    }
    
                    &:last-of-type {
                        transform: rotate(-45deg) translate(1px, -1px);
                    }
                }
    
                &.active {
                    div {
                        transform: none;
                        opacity: 1;
                        margin: 5px auto;
                    }
                }
            }
        }
    }

    .pages {
        list-style: none;
        padding-left: 0px;
        overflow-y: overlay;
        flex-grow: 1;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        
        &::-webkit-scrollbar {
            display: none;
        }

        .item-link {
            text-decoration: none;
            
            .item {
                opacity: 0%;
                
                width: 100%;
                display: flex;
                align-items: center;
                padding-top: 10px;
                padding-bottom: 10px;
                transition: all 0.5s ease;

                .arrow {
                    position: absolute;
                    right: 25px;

                    border: solid var(--primary-accent-font-color);
                    border-width: 2px 0px 0px 2px;
                    display: inline-block;
                    padding: 3px;
                    height: 0.2rem;
                    width: 0.2rem;
                    transform: rotate(135deg);
                    -webkit-transform: rotate(135deg);
                    
                    transition: right 0.2s ease-in-out;
                }

                .title {
                    padding-left: 10px;
                    text-decoration: none;
                    color: var(--primary-accent-font-color);
                    font-size: 1.1rem;
                    font-family: var(--font-family);
                    font-weight: bold;
                }
                    
                &:hover {
                    background-color: var(--primary-accent-font-color) !important;
                    
                    .title {
                        color: var(--primary-accent-background);
                    }
                    
                    .arrow {
                        border: solid var(--primary-accent-background);
                        border-width: 2px 0px 0px 2px;
                        right: 20px;
                    }
                }
            }
        }

        .page {
            opacity: 0%;
            transition: opacity 0.5s ease;
            width: 100%;

            .dropdown-toggle {
                width: 100%;
                display: flex;
                padding-top: 10px;
                padding-bottom: 10px;

                background: none;
                border: none;

                color: var(--primary-accent-font-color);
                font-size: 1.1rem;
                font-weight: bold;
                cursor: pointer;
                transition: background-color 0.5s ease;

                &:hover {
                    color: var(--primary-accent-background);
                    background-color: var(--primary-accent-font-color) !important;

                    .label {
                        color: var(--primary-accent-background);
                    }

                    .arrow {
                        div {
                            background-color: var(--primary-accent-background);
                        }
                    }
                }

                .label {
                    margin-left: 10px;
                    margin-right: auto;
                }

                .arrow {
                    margin-left: auto;
                    margin-right: 30px;

                    div {
                        background-color: var(--primary-accent-font-color);
                        width: 2px;
                        height: 10px;
                        display: inline-block;

                        &:first-of-type {
                            transform: rotate(-45deg);
                        }
                        &:nth-child(2) {
                            transform: translateX(5px) rotate(45deg);
                        }
                    }
                }

                &.active {
                    background: var(--secondary-accent-background);
                    
                    .arrow {
                        div {
                            &:first-of-type {
                                transform: rotate(45deg);
                            }
                            &:nth-child(2) {
                                transform: translateX(5px) rotate(-45deg);
                            }
                        }
                    }
                }
            }

            .dropdown-menu {
                &.active {
                    li {
                        a {
                            background-color: var(--secondary-accent-background);
                        }
                    }
                }

                overflow-y: hidden;
                transition: all 0.5s ease;
                list-style: none;
                padding-left: 0;

                li {
                    a {
                        font-style: none;
                        text-decoration: none;
                        padding: 10px;
                        color: var(--primary-accent-font-color);
                        display: block;
                        font-size: 1rem;
                        transition: background-color 0.5s ease;
                        
                        &:hover {
                            color: var(--primary-accent-background);
                            background-color: var(--primary-accent-font-color) !important;
                        }
                    }
                }
            }
        }
    }

    .footer-link {
        text-decoration: none;
    }

    .footer {
        justify-self: flex-end;
        height: 2.5rem;
        
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 100%;
        cursor: initial;

        transition: opacity 0.5s ease-in-out;

        &:hover {
            background-color: var(--secondary-accent-background);

            .back {
                .arrow {
                    left: 10px;
                }
            }
        }

        .back {
            width: 80%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            position: relative;

            .arrow {
                position: absolute;
                left: 20px;

                border: solid var(--primary-accent-font-color);
                border-width: 0 2px 2px 0;
                display: inline-block;
                padding: 3px;
                height: 0.2rem;
                width: 0.2rem;
                transform: rotate(135deg);
                -webkit-transform: rotate(135deg);
                
                transition: left 0.2s ease-in-out;
            }

            .label {
                color: var(--primary-accent-font-color);
            }
        }
    }
}