.section {
    border-bottom: 1px dashed var(--secondary-font-color);

    h1 {
        font-weight: 500;
    }

    h2 {
        font-size: 1.8rem;  
        font-weight: 500;
    }

    .subsection {
        margin-bottom: 45px;

        .subcontent {
            &>span {
                display: block;
                font-size: 1.1rem;
                margin-bottom: 15px;
            }

            &>pre {
                margin-bottom: 20px;
                font-size: 1rem;

                code {
                    background-color: var(--secondary-background);
                    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
                }
            }

            .syntax-options {
                .option-seperator {
                    color: var(--secondary-font-color);
                    font-size: 0.7rem;
                    margin-left: 30px;
                    display: block;
                    width: 100%;
                }
            }
        }
    }
}